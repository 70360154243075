/** @jsx jsx */
import { jsx } from "@emotion/core"

import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import Image from "../components/image"
import { urlBrokerArea } from "../utils/siteConfig"

import Card from "react-bootstrap/Card"
import Icon from "../components/Icona"
import { Parallax, ParallaxBanner } from "react-scroll-parallax"
import asyncComponent from "../components/Hoc/asyncComponent"

const SliderCompanies = asyncComponent(() => {
  return import("../components/Slider/SliderCompanies")
})

const IndexPage = () => {
  const features = [
    {
      img: require("../images/icons/contract.svg"),
      title: `Comparez plus de 15${"\u00A0"}contrats`,
      description: "La tarification de tous vos partenaires en 1 seul outil.",
    },
    {
      img: require("../images/icons/exclusif.svg"),
      title: "Des contrats exclusifs",
      description: "Bénéficiez de contrats performants et reconnus.",
    },
    {
      img: require("../images/icons/commission.svg"),
      title: "Commissions personnalisées",
      description: "Précompte, Linéaire ou escompte choisissez librement !",
    },
    {
      img: require("../images/icons/tool.svg"),
      title: `Mode de gestion au${"\u00A0"}choix`,
      description: "Gestion Directe ou Gestion Déléguée, vous avez la main.",
    },
  ]

  return (
    <Layout footerBg={"#fff"}>
      <SEO title="Home" />

      <Section className="py-0">
        <ParallaxBanner
          layers={[
            {
              image: require("../images/vectors/bg-with-vectors.svg"),
              amount: 0.1,
            },
          ]}
          style={{
            height: "auto",
          }}
        >
          <div
            className="pt-5 d-flex align-items-center"
            css={{
              minHeight: "85vh",
              background: `linear-gradient(180deg, rgba(244,246,254, 1) 50%, rgba(255,255,255, 1) 100%)`,
            }}
          >
            <Container>
              <Row
                className="align-items-center"
                css={{
                  padding: "60px 0",
                  "@media (min-width: 992px)": {
                    padding: "100px 0",
                  },
                }}
              >
                <Col md={7} className="mb-4 mb-md-0 text-center text-md-left">
                  <h1 className="h1-home">
                    <span className="text-secondary">La MarketPlace BtoB</span>
                    <br />
                    N°1 en Assurance de{"\u00A0"}Prêt
                  </h1>
                  <p className="mt-4">
                    <strong className="text-secondary">
                      Comparer les offres des leaders du marché
                    </strong>{" "}
                    de l’Assurance Emprunteur avec une plateforme 100% Digitale
                    et Innovante.
                  </p>
                  <div className="d-flex justify-content-center justify-content-md-start">
                    <h5 className="text-primary mb-0 mr-4">
                      <Icon
                        icon={"check"}
                        color="#a0d468"
                        width={32}
                        height={32}
                      />{" "}
                      100% Gratuit
                    </h5>
                    <h5 className="text-primary mb-0">
                      <Icon
                        icon={"check"}
                        color="#a0d468"
                        width={32}
                        height={32}
                      />{" "}
                      Indépendant
                    </h5>
                  </div>
                  <div className="mt-4 mt-lg-5 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-start">
                    <Link
                      className="btn btn-secondary btn-width mr-sm-3 mb-2 mb-sm-0"
                      to="/inscription/"
                    >
                      Devenir partenaire
                    </Link>
                    <a
                      className="btn btn-primary btn-width"
                      href={urlBrokerArea}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Se connecter{" "}
                      <Icon icon={"box-arrow-in-right"} color="#fff" />
                    </a>
                  </div>
                </Col>
                <Col md={5}>
                  <div
                    css={{
                      position: "relative",
                      maxWidth: "65%",
                      margin: "0 auto",
                      "@media (min-width: 768px)": {
                        maxWidth: "90%",
                      },
                    }}
                  >
                    <div
                      css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <img
                        className="w-100"
                        src={require("../images/devices/mobile-1-circle.svg")}
                        alt=""
                      />
                    </div>
                    <Parallax y={[30, -30]}>
                      <Image
                        filename="devices/mobile-1-test.png"
                        alt="Téléphone"
                      />
                    </Parallax>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParallaxBanner>
        <div
          className="py-5 d-flex align-items-center"
          css={theme => ({
            background: theme.gradients.primary,
            "@media (min-width: 992px)": {
              height: "15vh",
            },
          })}
        >
          <Container>
            <div className="d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap">
              <p className="mb-0 mr-3 text-white text-center text-md-left text-lg-center mb-3 mb-md-0">
                Institutionnels et courtiers n'attendez plus et testez dès
                maintenant notre{"\u00A0"}solution
              </p>
              <a
                className="flex-shrink-0 btn btn-tertiary py-2 px-3"
                href={urlBrokerArea}
                target="_blank"
                rel="noreferrer"
              >
                C'est parti !
              </a>
            </div>
          </Container>
        </div>
      </Section>

      <ParallaxBanner
        layers={[
          {
            image: require("../images/vectors/bg-vectors-2.svg"),
            amount: 0.1,
            props: {
              style: { backgroundSize: "auto" },
            },
          },
        ]}
        style={{
          height: "auto",
        }}
      >
        <Section
          className="pb-0"
          css={{
            overflow: "visible",
            "@media (min-width: 992px)": {
              paddingTop: "140px",
            },
          }}
        >
          <Container>
            <Row>
              <Col md="6" className="order-last order-md-first">
                <div
                  css={{
                    "@media (min-width: 768px)": {
                      position: "absolute",
                      width: "50vw",
                      top: 0,
                      right: "12%",
                      height: "100%",
                    },
                  }}
                >
                  <Image
                    filename="others/meeting.jpg"
                    alt="Réunion"
                    className="h-100 rounded-right rounded-lg shadow-lg"
                  />
                </div>
              </Col>
              <Col
                md="6"
                className="px-md-0 mb-4 mb-md-0"
                css={{
                  "@media (min-width: 768px)": {
                    paddingTop: "100px",
                    paddingBottom: "100px",
                  },
                }}
              >
                <h2>
                  <span className="font-weight-light">
                    Développez votre chiffre d'affaires avec
                  </span>{" "}
                  l'Assurance Emprunteur
                </h2>
                <p className="my-4 my-lg-5">
                  <strong className="text-secondary">
                    Acteur incontournable du B2B
                  </strong>
                  , Simulassur met{" "}
                  <strong className="text-secondary">
                    l’Innovation et la Technologie au coeur de son expertise
                    Métier
                  </strong>{" "}
                  pour répondre aux besoins des professionnels de l’assurance,
                  du crédit et des établissements bancaires.
                </p>
                <Link
                  className="btn btn-secondary btn-width"
                  to="/inscription/"
                >
                  Contactez nous
                </Link>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          css={{
            "@media (min-width: 992px)": {
              padding: "140px 0",
            },
          }}
        >
          <Container>
            <h2 className="text-center mb-2 mb-md-4 mb-lg-3">
              Une plateforme simple
              <br />{" "}
              <span className="font-weight-light">Intuitive et sur-mesure</span>
            </h2>
            <Row>
              {features.map(feature => (
                <Col
                  key={feature.title}
                  md={6}
                  xl={3}
                  className="d-flex mt-3 mt-lg-5 text-center"
                >
                  <Card className="w-100 rounded-lg shadow p-4">
                    <img
                      className="img-fluid mb-3 mx-auto"
                      width={80}
                      src={feature.img}
                      alt={feature.title}
                    />
                    <h5 className="text-primary mb-0">{feature.title}</h5>
                    <p
                      className="mt-2 mb-0"
                      css={{
                        fontSize: "1rem",
                      }}
                    >
                      {feature.description}
                    </p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Section>
      </ParallaxBanner>

      <Section
        className="py-5"
        css={theme => ({ background: theme.gradients.primary })}
      >
        <Container className="text-center text-white">
          <h2 className="mb-4">
            <span className="font-weight-light">Comparez</span> les leaders du
            marché{"\u00A0"}!
          </h2>
        </Container>
        <Container>
          <SliderCompanies />
        </Container>
      </Section>

      <Section className="bg-white">
        <Container>
          <Row className="align-items-center">
            <Col md="6" className="mb-4 mb-md-0">
              <h2>
                <span className="font-weight-light">
                  Un espace de souscription
                </span>
                <br />
                100%{"\u00A0"}Digital
              </h2>
              <p className="my-4 my-lg-5">
                ZERO PAPIER ! Notre parcours de{" "}
                <strong className="text-secondary">
                  souscription Online fluide et rapide
                </strong>{" "}
                intègre les questionnaires médicaux, la signature électronique
                et l’édition du certificat d’adhésion.
              </p>
              <Row>
                <Col lg={6} className="mb-2 mb-lg-0">
                  <Card className="p-3 rounded-lg shadow flex-row align-items-center">
                    <img
                      src={require("../images/icons/clock.svg")}
                      width={40}
                      alt="icon clock"
                    />
                    <h6 className="text-center text-primary mb-0 flex-grow-1">
                      15 minutes pour souscrire
                    </h6>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="p-3 rounded-lg shadow flex-row align-items-center">
                    <img
                      src={require("../images/icons/medical.svg")}
                      width={40}
                      alt="icon clock"
                    />
                    <h6 className="text-center text-primary mb-0 flex-grow-1">
                      90% de réponse médicale immédiate
                    </h6>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Parallax y={[30, -30]}>
                <Image
                  filename="devices/mobile-2.png"
                  alt="Dashboard"
                  css={{
                    maxWidth: "80%",
                    margin: "0 auto",
                    "@media (min-width: 768px)": {
                      maxWidth: "90%",
                    },
                  }}
                />
              </Parallax>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
